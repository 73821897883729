<template>
  <div class="app-container">
    <el-card>
      <div slot="header" class="card-header">
        <h3 style="margin: 0;">Subordinate MPP Monitoring</h3>
        <div>
          <el-select v-if="!isViewMPP" v-model="selectedEvalPeriod" @change="handleChange()" placeholder="Select Evaluation Period" size="mini">
            <el-option v-for="mpp in mppList" :key="mpp.id" :value="mpp.id" :label="mpp.name"></el-option>
          </el-select>
        </div>
      </div>
      <div v-if="isViewMPP">
        <el-row type="flex" justify="space-between">
          <el-col :xs="20" :sm="23" :md="24" :lg="23" :xl="23">
            <el-button @click="isViewMPP = false" size="mini"><i class="el-icon-back"></i></el-button>
          </el-col>
          <el-col :xs="3" :sm="2" :md="20" :lg="1" :xl="1">
            <el-progress type="circle" width="45" :percentage="computeProgressBar()"></el-progress>
          </el-col>
        </el-row>
        <el-row type="flex" justify="space-between">
          <el-col :xs="24" :sm="19" :lg="20" :xl="23" style="margin-right: auto;">
            <h1>{{selectedEmployee.name + ' - ' + selectedEmployee.bp_id_name}}</h1>
          </el-col>
          <el-col :xs="10" :sm="3" :md="12" :lg="2" :xl="2">
            <el-button size="mini" @click="updateStatus(2)">Approve</el-button>
          </el-col>
          <el-col :xs="10" :sm="3" :md="12" :lg="2" :xl="2">
            <!-- <el-button size="mini" @click="updateStatus(2)">Approve</el-button>   -->
            <el-button size="mini" @click="updateStatus(3)">Disapprove</el-button>
          </el-col>
        </el-row>
        <el-row>
          <el-col>
            <el-collapse v-for="(parentItem, parentIndex) in mppTableData" :key="parentIndex">
              <el-collapse-item :title="parentItem.category_bg4_id_name + ' - ' + parentItem.weight + '%'" :name="parentItem.category_bg4_id_name">
                <div v-for="(childItem, childIndex) in parentItem.perspective" :key="childIndex">
                  <h1 style="font-size:13px !important;">{{childItem.perspective_bg4_id_name}}</h1>
                  <div v-for="(grandChildItem, grandChildIndex) in childItem.strategic_objectives" :key="grandChildIndex">
                    <h2>{{grandChildItem.objective_bg4_id_name}}</h2>
                    <el-table
                      :data="grandChildItem.attributes"
                      style="width: 100%">
                      <el-table-column
                        prop="weight"
                        label="% Weight"
                        >
                      </el-table-column>
                      <el-table-column
                        prop="measurement_bg4_id_name"
                        label="Strategic Measurements (KPI)"
                        >
                      </el-table-column>
                      <el-table-column
                        prop="threshold"
                        label="Threshold"
                        >
                      </el-table-column>
                      <el-table-column
                        prop="target"
                        label="Target"
                        >
                      </el-table-column>
                      <el-table-column
                        prop="superior"
                        label="Superior"
                        >
                      </el-table-column>
                      <el-table-column
                        prop="actual"
                        label="Actual"
                        >
                      </el-table-column>
                      <el-table-column
                        prop="employee_document_mpp_bsc.rating"
                        label="Rating"
                        >
                        <template slot-scope="scope">
                          <div v-if="scope.row.employee_document_mpp_bsc">
                            <span v-if="!scope.row.isEdit">{{scope.row.employee_document_mpp_bsc.rating}}</span>
                            <el-input v-model="scope.row.employee_document_mpp_bsc.rating" v-else type="number"></el-input>
                          </div>
                          <span v-else>
                            -
                          </span>
                        </template>
                      </el-table-column>
                      <el-table-column
                        fixed="right"
                        label="Action"
                        >
                        <template slot-scope="scope">
                          <div v-if="scope.row.employee_document_mpp_bsc">
                            <el-tooltip content="Edit" placement="top" v-if="!scope.row.isEdit">
                              <el-button type="text" :disabled="scope.row.employee_document_mpp_bsc.is_self_rate === '1'" @click="edit(parentIndex, childIndex, grandChildIndex, scope.$index, true)"><i class="el-icon-edit"></i></el-button>
                            </el-tooltip>
                            <el-tooltip content="Confirm Edit" placement="top" v-else>
                              <el-button type="text" @click="updateRating(scope.row)"><i class="el-icon-check"></i></el-button>
                            </el-tooltip>
                          </div>
                          <span v-else>
                            -
                          </span>
                        </template>
                      </el-table-column>
                    </el-table>
                    <h3>Sub-Total: {{computeTotal(grandChildItem)}}</h3>
                  </div>
                  <hr>
                </div>
              </el-collapse-item>
            </el-collapse>
          </el-col>
        </el-row>
      </div>
      <div v-else>
        <el-row :gutter="24">
          <el-col :span="5" style="float: right;">
            <el-input
              v-model="employeeSearchText"
              size="mini"
              @click="hello(scope.row)"
              placeholder="Search for Employee"
            />
          </el-col>
          <el-col :span="5" style="float: right;">
            <el-select v-model="statusFilter" placeholder="Select Status" size="mini" clearable>
              <el-option v-for="status in statuses" :key="status.id" :value="status.name" :label="status.name"></el-option>
            </el-select>
          </el-col>
        </el-row>
        <el-table
          :data="tableData.filter(data => data.a_id_name !== null ? !statusFilter && !employeeSearchText || data.a_id_name.toLowerCase().includes(statusFilter.toLowerCase()) && data.name.toLowerCase().includes(employeeSearchText.toLowerCase()) : '')"
          ref="multipleTable"
          @selection-change="handleSelectionChange"
          style="width: 100%">
          <el-table-column
            prop="name"
            label="Employee Name"
            >
          </el-table-column>
          <el-table-column
            prop="bp_id_name"
            label="Job Title"
            >
          </el-table-column>
          <el-table-column
            prop="bj_id_name"
            label="Rank"
            >
          </el-table-column>
          <el-table-column
            prop="a_id_name"
            label="Status"
            >
            <template slot-scope="scope" style="text-align: center;">
                <!-- {{scope.row}} -->
                <el-tag type="success" v-if="scope.row.a_id_name === 'Completed' || scope.row.a_id_name === 'Approved'">{{scope.row.a_id_name}}</el-tag>
                <el-tag v-if="scope.row.a_id_name === 'Waiting'">{{scope.row.a_id_name}}</el-tag>
                <el-tag type="danger" v-if="scope.row.a_id_name === 'Disapproved'">{{scope.row.a_id_name}}</el-tag>
            </template>
          </el-table-column>
          <!-- <el-table-column
            label="Set MPP"
            >
            <template slot="header" slot-scope="scope">
              <el-checkbox v-model="checkedAllMPP" @change="handleSelectSend($event, scope.row)" :disabled="tableData.length === 0"></el-checkbox>
              <span style="margin-left: 10px;">Set MPP</span>
            </template>
            <template slot-scope="scope">
              <el-checkbox v-model="scope.row.set_mpp"></el-checkbox>
            </template>
          </el-table-column> -->
          <el-table-column
            label="Action"
            >
            <template slot-scope="scope">
              <el-button style="background:#EB2C3E; color:white;" @click="viewMPP(scope.row)" size="mini">View MPP</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-card>
  </div>
</template>

<script>

export default {
  mounted () {
    this.getMPP()
  },
  data () {
    return {
      statuses: [
        {
          id: '1',
          name: 'Waiting'
        },
        {
          id: '2',
          name: 'Completed'
        },
        {
          id: '3',
          name: 'Approved'
        },
        {
          id: '4',
          name: 'Disapproved'
        }
      ],
      statusFilter: '',
      selectedEmployee: null,
      mppTableData: [],
      isViewMPP: false,
      multipleSelection: [],
      mppList: [],
      selectedEvalPeriod: '',
      employeeSearchText: '',
      checkedAllSR: false,
      tableData: [],
      checkedAllMPP: false,
      totalAttributes: 0,
      completedAttributes: 0
      // tableData: [
      //   {
      //     employee_name: 'Cj Dy',
      //     department: 'Information Technology Department',
      //     set_mpp: true,
      //     self_rate: false
      //   },
      //   {
      //     employee_name: 'Patrick Nanzan',
      //     department: 'Information Technology Department',
      //     set_mpp: false,
      //     self_rate: true
      //   },
      //   {
      //     employee_name: 'Luigi Octaviano',
      //     department: 'Information Technology Department',
      //     set_mpp: false,
      //     self_rate: true
      //   },
      //   {
      //     employee_name: 'Melvin Borja',
      //     department: 'Information Technology Department',
      //     set_mpp: false,
      //     self_rate: true
      //   }
      // ]
    }
  },
  watch: {
    checkedAllMPP: {
      handler (value) {
        if (value) {
          this.tableData.forEach(e => {
            e.isChecked = true
          })
        } else {
          this.tableData.forEach(e => {
            e.isChecked = false
          })
        }
      },
      immediate: true
    }
  },
  methods: {
    updateStatus (value) {
      var formSubmit = {}
      formSubmit.id = this.selectedEmployee.id
      formSubmit.a_id = value
      this.$http
        .post('mpp-employee-update-status', formSubmit)
        .then(res => {
          if (res.data.StatusCode) {
            this.$message({
              showClose: true,
              message: 'Form Submitted',
              type: 'success'
            })
            this.handleChange()
            this.viewMPP(this.selectedEmployee)
          } else {
            this.$message({
              showClose: true,
              message: res.data.body,
              type: 'error'
            })
          }
        })
    },
    computeProgress (item) {
      this.totalAttributes = item.attributes.length
    },
    edit (parentIndex, childIndex, grandChildIndex, tableIndex, action) {
      this.mppTableData[parentIndex].perspective[childIndex].strategic_objectives[grandChildIndex].attributes[tableIndex].isEdit = action
    },
    updateRating (item) {
      console.log(item)
      if (item.employee_document_mpp_bsc.rating !== '' && item.employee_document_mpp_bsc.rating !== 'e') {
        var formSubmit = {}
        formSubmit.id = item.employee_document_mpp_bsc.edmb_id
        formSubmit.edm_id = item.employee_document_mpp_bsc.edm_id
        formSubmit.rating = item.employee_document_mpp_bsc.rating
        this.$http
          .post('mpp-employee-update-rating', formSubmit)
          .then(res => {
            if (res.data.StatusCode) {
              this.$message({
                showClose: true,
                message: 'Form Submitted',
                type: 'success'
              })
              this.viewMPP(this.selectedEmployee)
            } else {
              this.$message({
                showClose: true,
                message: res.data.body,
                type: 'error'
              })
            }
          })
      } else {
        this.$message({
          showClose: true,
          message: 'Please enter a Rating before submitting!',
          type: 'error'
        })
      }
    },
    computeProgressBar () {
      var computation = Math.round(((this.completedAttributes / this.totalAttributes) * 100))
      if (computation) {
        return computation
      } else {
        return '-'
      }
    },
    viewMPP (item) {
      this.completedAttributes = 0
      this.totalAttributes = 0
      this.selectedEmployee = item
      console.log(item)
      var formSubmit = {}
      formSubmit.bd_id = item.bd_id
      formSubmit.bms_id = item.bms_id
      formSubmit.bj_id = item.bj_id
      formSubmit.bds_id = item.bds_id
      formSubmit.hme_id = item.hme_id
      formSubmit.em_id = item.em_id
      this.$http
        .post('mpp-view-employee-bsc', formSubmit)
        .then(res => {
          console.log(res)
          if (res.data.StatusDescription === 'Sorry! Nothing was found.') {
            this.$message({
              showClose: true,
              message: res.data.StatusDescription,
              type: 'error'
            })
          } else {
            res.data.body.forEach(e => {
              e.perspective.forEach(f => {
                f.strategic_objectives.forEach(g => {
                  this.totalAttributes += g.attributes.length
                  g.attributes.forEach(h => {
                    console.log(h, 'h')
                    if (h.employee_document_mpp_bsc) {
                      if (h.employee_document_mpp_bsc.rating !== '.00') {
                        this.completedAttributes++
                      }
                    }
                    this.$set(h, 'isEdit', false)
                  })
                })
              })
            })
            this.mppTableData = res.data.body
            this.isViewMPP = true
          }
        })
    },
    computeTotal (item) {
      var total = 0
      item.attributes.forEach(e => {
        total += parseFloat(e.weight)
      })
      if (isNaN(total)) {
        return ''
      }
      return total.toFixed(1) + '%'
    },
    handleSelectionChange (val) {
      var newFormat = val.map(e => e.id)
      this.multipleSelection = newFormat
    },
    getMPP () {
      this.$http
        .get('mpp-completed-evaluation-list')
        .then(res => {
          console.log(res)
          this.mppList = res.data.body
        })
    },
    handleChange () {
      this.$http
        .get('mpp-employee-list/' + this.selectedEvalPeriod)
        .then(res => {
          if (res.data.StatusDescription === 'Sorry! Nothing was found.') {
            this.checkedAllSR = false
            this.tableData = []
          } else {
            res.data.body.forEach(e => {
              e.isChecked = false
            })
            if (!res.data.body.filter(e => e.is_self_rate === '0').length > 0) {
              this.checkedAllSR = true
            } else {
              this.checkedAllSR = false
            }
            this.tableData = res.data.body
          }
        })
    },
    hello (scope) {
      console.log(scope, 'scope')
    }
  }
}
</script>
<style>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
</style>
